<template>
    <div class="page-404">
        <h1 class="heading">404</h1>
        <h2 class="heading">Page not found</h2>
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss"></style>